const DOCTOR_INFO = {
  first: {
    name: '김혜인',
    career: [
      '현) 해득금천한의원 대표원장',
      '전) 광덕안정한의원 일산주엽점 진료원장',
      '전) 미올한의원 파주운정점 진료원장',
      '전) 누베베한의원 강남점 진료원장',
      '원광대학교 한의과대학 졸업',
      '한방비만학회 회원',
      '대한약침학회 회원',
      '대한한방부인과학회 회원',
      '척추신경추나의학회 회원',
      '대한한의학회 회원',
      '턱관절균형의학회 FCST 일반과정 수료'
    ],
  },
  // 왼쪽 의사 정보
  // 한줄에 하나씩 '이런식으로 따옴표안에 작성' 해서  쉼표로 구분해서 작성
  // 엔터치시면 안되고 그냥 쭉 늘여쓰면 알아서 줄바꿈됩니다
  second: {
    name: '김우림',
    career: [
      '현) 해득금천한의원 진료원장',
      '전) 세교S한의원 진료원장',
      '전) 연도보건지소 진료원장',
      '전) 경기도 역학조사관',
      '우석대학교 한의과대학 졸업',
      '한방비만학회 회원',
      '대한약침학회 회원',
      '대한한의학회 회원',
    ],
  },
  // 오른쪽 의사 정보
  // 한줄에 하나씩 '이런식으로 따옴표안에 작성' 해서  쉼표로 구분해서 작성
  
  third: {
    name: '심채영',
    career: [
      '현) 해득금천한의원 진료원장',
      '전) 라현한방병원 진료원장',
      '부산대학교 한의학전문대학원 졸업',
      '부산대학교 한의학전문대학원 한의학 석사',
      '미국진단초음파협회 ARDMS APCA 근골격계 초음파자격(RMSK) 취득',
      '대한스포츠한의학회 팀닥터 프로그램 수료',
      '대한한방비만학회 한방비만치료 전문가과정 수료',
      '대한스포츠한의학회 정회원',
      '대한한방비만학회 회원',
    ],
  },
};

export default DOCTOR_INFO;
